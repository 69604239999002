// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'ch',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '33e027330198a0afc245af010070f82b9d4743fd',
    releaseTagRef: '1ed81a3',
    releaseTimestamp: '1725523050',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: 'clicks.flaming.burger-king.ch',
    adyen: {
      clientKey: 'live_L6BRV3FK4FFZVN2XFXYC6WCMRALGGK6O',
      environment: 'live',
      urlPrefix: 'https://checkoutshopper-live.adyen.com',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '',
      branch: 'key_live_ekX2Ww4SVYojRlaP5tvFppgmFBd9hVXS',
      braze: '56e3860e-5510-4a3b-80ed-4ac999662b43',
      brazeCluster: 'sdk.fra-01.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyDOtGSKPthz3am_QTmuavFu42QiIcVORm0',
      googleMapsAndroid: '',
      googleMapsIOS: '',
      googleMapsWeb: '',
      tomtom: '',
      launchDarkly: '64468750bb88121262dd1179',
      launchDarklyMobileKey: 'mob-0e7920f3-f36a-4709-bf7f-59a6989d7fcb',
      mParticle: 'eu1-4d9fc3be5c3c8146a35db8f2e7290be2',
      radar: '',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: 'merchant.com.bk.native.tillster.prod',
      migrationMerchantId: '',
      paymentsNetworks: ['MasterCard','Visa','Discover'],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: 'com.bk.ch.sociallogin',
        redirectURI: 'https://bk-ch-a32c3.firebaseapp.com/__/auth/handler',
      },
    },
    branch: {
      domains: ['burgerkingch.app.link','burgerkingch-alternate.app.link','burgerkingch.test-app.link','burgerkingch-alternate.test-app.link']
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '62ev1gv53cp9bckrt5gdinrqmj',
      userPoolId: 'eu-central-1_dwCOsnxKY',
    },
    bringg: {
      region: '5',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '9999',
    },
    forter: {
      siteId: '6ebc7d54b003',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'firstdata',
      marketPaymentsGatewayMerchantId: '456031998994',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '456031998994',
        ch: '456031998994',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '912447693337-hos3ji5cj0t7aee12em9ohjkcmho36ld.apps.googleusercontent.com',
        redirectURI: 'https://bk-ch-a32c3.firebaseapp.com/__/auth/handler',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-P8PDD5W',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: 'C0004',
        consentCategoryBraze: 'C0002',
        consentCategoryMParticle: 'C0001',
        globalConsentState: 'Opt-Out',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://sdk.fra-01.braze.eu',
      firstData: 'https://prod.api.firstdata.com',
      webBaseUrl: 'https://www.burger-king.ch',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: '',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: 'https://apps.apple.com/us/app/burger-king-ch/id1550130856',
    androidDeepLink: 'https://play.google.com/store/apps/details?id=com.bk.ch',
    cookieBanners: {
      cookieBanner:'customCookieBanner',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: '',
          consentCategoryMParticle: '',
          consentCategoryDdRum: '',
          globalConsentState: '',
          scriptUrl: '',
          scriptId: '',
        },
      },
    }
  };